.st0 {
  fill: #e2761b;
  stroke: #e2761b;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st1 {
  fill: #e4761b;
  stroke: #e4761b;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st2 {
  fill: #d7c1b3;
  stroke: #d7c1b3;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st3 {
  fill: #233447;
  stroke: #233447;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st4 {
  fill: #cd6116;
  stroke: #cd6116;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st5 {
  fill: #e4751f;
  stroke: #e4751f;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st6 {
  fill: #f6851b;
  stroke: #f6851b;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st7 {
  fill: #c0ad9e;
  stroke: #c0ad9e;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st8 {
  fill: #161616;
  stroke: #161616;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st9 {
  fill: #763d16;
  stroke: #763d16;
  stroke-linecap: round;
  stroke-linejoin: round;
}
